<div class="modal-header">
	<h2 translate="SuperDebtors.IdentityForm.Title"></h2>
	<button type="button" class="modal-button-close fal fa-times" (click)="activeModal.dismiss()"></button>
</div>

<form [formGroup]="form" [class.block-loader]="loader.isLoading$ | async" (submit)="onSubmit()" autocomplete="off">
	@if (!isImportMenuDisabled) {
		<ngb-alert type="warning" [dismissible]="false" translate="SuperDebtors.IdentityForm.UpdateWarning"></ngb-alert>
	}

	<loader-spinner [model]="loader"/>

	<div class="modal-body" [class.invoicing-disabled]="!manualInvoicingEnabled">
		<div class="row">
			@if (isImportMenuDisabled) {
				<div class="col-md-5 mr-5">
					<div class="form-group">
						<label class="form-label form-label-required" for="field-functionalId" translate="Placeholders.SuperDebtorId"></label>
						<div class="input-group">
							<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-functionalId"
							formControlName="functionalId" [placeholder]="'Placeholders.SuperDebtorId' | translate" [class.is-invalid]="functionalIdField.dirty && functionalIdField.invalid" />
						</div>
						<form-control-feedback [field]="functionalIdField" [messages]="{alreadyUsedIdentifier: 'Forms.FieldErrorMessages.SuperDebtorAlreadyExists'}"/>
					</div>
				</div>
	
				<div class="col-md-5">
					<div class="form-group">
						<label class="form-label form-label-required" for="field-companyName" translate="Forms.CommonLabels.CompanyName"></label>
						<div class="input-group">
							<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-companyName"
							formControlName="companyName" [placeholder]="'Forms.CommonLabels.CompanyName' | translate" [class.is-invalid]="companyNameField.dirty && companyNameField.invalid" />
						</div>
						<form-control-feedback [field]="companyNameField"/>
					</div>
				</div>
			}

			<div class="col-lg-5 mr-5">
				<div class="form-group">
					<label class="form-label" for="field-legalIdentifier" translate="SuperDebtors.IdentityForm.Form.Siret"></label>
					<input *featureFlag="'superDebtorLegalIdMask'; else withoutMask" type="text" autocomplete="off" class="form-control form-control-alt" id="field-legalIdentifier" formControlName="legalIdentifier" [class.is-invalid]="legalIdentifierField.dirty && legalIdentifierField.invalid" [mask]="legalIdentifierMask" />
					<ng-template #withoutMask><input type="text" autocomplete="off" class="form-control form-control-alt" id="field-legalIdentifier" formControlName="legalIdentifier" [class.is-invalid]="legalIdentifierField.dirty && legalIdentifierField.invalid"/></ng-template>
					<form-control-feedback [field]="legalIdentifierField"/>
				</div>
			</div>

			@if (manualInvoicingEnabled) {
				<div class="col-lg-5">
					<div class="form-group">
						<label class="form-label" for="field-vatNumber" translate="SuperDebtors.Infos.VATNumber"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-vatNumber" formControlName="vatNumber" [class.is-invalid]="vatNumberField.dirty && vatNumberField.invalid" />
						<form-control-feedback [field]="vatNumberField"/>
					</div>
				</div>
	
				<div class="col-lg-12 mb-1">
					<button
						class="btn-link text-decoration-none"
						[class.not-selected]="showInvoicingAddress"
						[class.text-muted]="showInvoicingAddress"
						type="button"
						translate="SuperDebtors.Infos.Postal"
						(click)="showInvoicingAddress = false"></button>
						/
					<button
						class="btn-link text-decoration-none"
						[class.not-selected]="!showInvoicingAddress"
						[class.text-muted]="!showInvoicingAddress"
						type="button"
						translate="SuperDebtors.Infos.Invoicing"
						(click)="showInvoicingAddress = true"></button>
				</div>
			}
			@else {
				<div class="invisible filler col-lg-5"></div><div class="invisible"></div>
			}

			@if (showInvoicingAddress) {
				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="billingAddressRequired" for="field-billingStreet" translate="SuperDebtors.IdentityForm.Form.Address"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-billingStreet" formControlName="billingStreet" [class.is-invalid]="billingStreetField.dirty && billingStreetField.invalid" />
						<form-control-feedback [field]="billingStreetField"/>
					</div>
				</div>

				<div class="address-part col-lg-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="billingAddressRequired" for="field-billingZipCode" translate="SuperDebtors.IdentityForm.Form.ZipCode"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-billingZipCode" formControlName="billingZipCode" [class.is-invalid]="billingZipCodeField.dirty && billingZipCodeField.invalid" />
						<form-control-feedback [field]="billingZipCodeField"/>
					</div>
				</div>

				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="billingAddressRequired" for="field-billingCity" translate="SuperDebtors.IdentityForm.Form.City"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-billingCity" formControlName="billingCity" [class.is-invalid]="billingCityField.dirty && billingCityField.invalid" />
						<form-control-feedback [field]="billingCityField"/>
					</div>
				</div>

				<div class="address-part col-lg-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="billingAddressRequired" for="field-billingCountryCode" translate="SuperDebtors.IdentityForm.Form.Country"></label>

						<select
							class="form-control form-control-alt"
							id="field-billingCountryCode"
							formControlName="billingCountryCode"
							[class.is-invalid]="billingCountryCodeField.dirty && billingCountryCodeField.invalid">
							<option [value]="undefined" disabled hidden translate="Placeholders.Select"></option>
							<option value="">{{ 'CommonTexts.EmptyChoice' | translate }}</option>
							@for (country of countries; track country) {
								<option [ngValue]="country.countryCode">
									{{ country.countryName }}
								</option>
							}
						</select>

						<form-control-feedback [field]="billingCountryCodeField"/>
					</div>
				</div>
			}
			@else {
				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="manualInvoicingEnabled" for="field-street" translate="SuperDebtors.IdentityForm.Form.Address"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-street" formControlName="street" [class.is-invalid]="streetField.dirty && streetField.invalid" />
						<form-control-feedback [field]="streetField"/>
					</div>
				</div>

				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="manualInvoicingEnabled" for="field-zipCode" translate="SuperDebtors.IdentityForm.Form.ZipCode"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-zipCode" formControlName="zipCode" [class.is-invalid]="zipCodeField.dirty && zipCodeField.invalid" />
						<form-control-feedback [field]="zipCodeField"/>
					</div>
				</div>

				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="manualInvoicingEnabled" for="field-city" translate="SuperDebtors.IdentityForm.Form.City"></label>
						<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-city" formControlName="city" [class.is-invalid]="cityField.dirty && cityField.invalid" />
						<form-control-feedback [field]="cityField"/>
					</div>
				</div>

				<div class="address-part col-lg-5 mr-5">
					<div class="form-group">
						<label class="form-label" [class.form-label-required]="manualInvoicingEnabled" for="field-countryCode" translate="SuperDebtors.IdentityForm.Form.Country"></label>

						<select
							class="form-control form-control-alt"
							id="field-countryCode"
							formControlName="countryCode"
							[class.is-invalid]="countryCodeField.dirty && countryCodeField.invalid">
							<option [value]="undefined" disabled hidden translate="Placeholders.Select"></option>
							<option value="">{{ 'CommonTexts.EmptyChoice' | translate }}</option>
							@for (country of countries; track country) {
								<option [ngValue]="country.countryCode">
									{{ country.countryName }}
								</option>
							}
						</select>

						<form-control-feedback [field]="countryCodeField"/>
					</div>
				</div>
			}
			
			<div class="col-lg-5 mr-5">
				<div class="form-group">
					<label class="form-label" [class.form-label-required]="manualInvoicingEnabled" for="field-email" translate="SuperDebtors.IdentityForm.Form.Email"></label>
					<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-email" formControlName="email" [class.is-invalid]="emailField.dirty && emailField.invalid" />
					<form-control-feedback [field]="emailField"/>
				</div>
			</div>

			<div class="col-lg-5 mr-5">
				<div class="form-group">
					<label class="form-label" for="field-phoneNumber" translate="SuperDebtors.IdentityForm.Form.PhoneNumber"></label>
					<input type="text" autocomplete="off" class="form-control form-control-alt" id="field-phoneNumber" formControlName="phoneNumber" [class.is-invalid]="phoneNumberField.dirty && phoneNumberField.invalid" />
					<form-control-feedback [field]="phoneNumberField"/>
				</div>
			</div>

			<div class="col-lg-5">
				<div class="form-group">
					<label class="form-label" for="field-language" translate="DataList.Columns.TemplateLanguage"></label>
					<select type="text" autocomplete="off" class="form-control form-control-alt"
						id="field-language" formControlName="{{formControlNames.language}}"
						[class.is-invalid]="languageField.dirty && languageField.invalid">
						<option [ngValue]="null" disabled hidden selected translate="CommonTexts.EmptyChoice"></option>
						<option value="">{{ 'CommonTexts.EmptyChoice' | translate }}</option>
						@for (lang of languages; track lang) {
							<option [value]="lang.value">{{ lang.label }}</option>
						}
					</select>
					<form-control-feedback [field]="languageField"/>
				</div>
			</div>
		</div>

	</div>

	@if (isImportMenuDisabled) {
		<div class="form-text mb-4" translate="Forms.RequiredFields"></div>
	}

	<div class="modal-footer">
		<button class="btn btn-default" type="button" translate="Actions.Cancel" data-cy="Cancel" (click)="activeModal.dismiss()"></button>
		<button class="btn btn-primary" type="submit" translate="Actions.Save" data-cy="Save" [disabled]="form.invalid"></button>
	</div>
</form>
