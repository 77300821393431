import { tap, switchMap, map, withLatestFrom, filter, takeUntil } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConstants, StoreLoader } from '@aston/foundation';

import { DunningTimelineStore } from '../../../dunnings-module/stores/dunning-timeline.store';
import * as DunningActionDetailStoreActions from '../../dunning-action-detail-store/actions';
import { DebtorsService, SuperDebtorDetailService } from '../../../debtors-module/services';
import { ReferentialStore } from '../../../shared-module/stores/referential';
import { catchWithAppError as catchError } from '../../app-store/utils';
import { WebSocketService } from '../../../shared-module/services';
import { WsEventType } from '../../../shared-module/enums';
import * as AppStoreActions from '../../app-store/actions';
import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';
import { SuperDebtorPageStore } from '../../../debtors-module/stores/super-debtor-page';
import { DebtorDetailContactFormComponent } from '../../../debtors-module/components/debtor-detail-contact-form/debtor-detail-contact-form.component';
import { IDebtorContactForm } from '../../../debtors-module/models';


@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreContactsEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private tlStore: DunningTimelineStore,
		private sdPageStore: SuperDebtorPageStore,
		private superDebtorDetailService: SuperDebtorDetailService,
		private modalService: NgbModal,
		private ws: WebSocketService,
		private referential: ReferentialStore,
		private debtorDetailService: DebtorsService) {
	}

	// Contact success
	loadSuperDebtorContactsRequestEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorContactsRequest),
		switchMap(action => this.superDebtorDetailService.getSuperDebtorContactsForAction(action.id).pipe(
			map(superDebtorContacts => featureActions.LoadSuperDebtorContactsSuccess({
				entity: superDebtorContacts,
				correlationParams: action.correlationParams
			})),
			catchError(error => of(featureActions.LoadSuperDebtorContactsFailure({error})))
		))
	));

	onConfirmDeleteSuperDebtorContact$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ConfirmDeleteSuperDebtorContact),
		map(action => AppStoreActions.OpenConfirmationModal(
			{textsKey: action.contact.isDefault ?
				`Debtors.Confirmations.DeleteDefaultContact.` :
				`Debtors.Confirmations.DeleteContact.`
			},
			featureActions.DeleteContactRequest(action.contact)
		))
	));

	onDeleteSuperDebtorContact$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteContactRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectState),
			this.sdPageStore.identityAsObservable()
		),
		switchMap(([action, state, identity]) => this.debtorDetailService.deleteContact(state.superDebtorId, action.entity.id).pipe(
			switchMap(_ => [
				featureActions.DeleteContactSuccess({ entity: action.entity }),
				featureActions.LoadSuperDebtorContactsRequest(identity.id),
			]),
			catchError(error => of(featureActions.DeleteContactFailure({error})))
		))
	));

	onOpenDebtorContactForm$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSuperDebtorContactForm),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorContacts),
		),
		map(([action, allContacts]) => {
			const modalRef = this.modalService.open(DebtorDetailContactFormComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			const modal = (modalRef.componentInstance as DebtorDetailContactFormComponent);

			modal.languages = this.referential.localizedLanguages();
			modal.allContacts = allContacts;
			modal.contact = action.contact;
			modal.submitForm.pipe(
				takeUntil(modal.destroySubscriptions$)
			).subscribe((entity: IDebtorContactForm) =>
				!entity.id ?
					this.store.dispatch(featureActions.CreateContactRequest(entity))
					: this.store.dispatch(featureActions.UpdateContactRequest(entity))
			);

			modal.formLoader = new StoreLoader(
				this.store.select(featureSelectors.selectContactFormIsLoading),
				this.store.select(featureSelectors.selectContactFormError)
			);

			return featureActions.SetSuperDebtorContactFormModal({modal: modalRef});
		})
	));

	// Bug 35578: Reload timeline on contact deletion
	updateDunningTimelineEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateContactSuccess, featureActions.UpdateContactSuccess, featureActions.DeleteContactSuccess),
		map(() => AppStoreActions.Execute(() => this.tlStore.reload()))
	));

	reloadOpenContact$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorContactsSuccess),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		filter(([_, state]) => !!(state.contactFormModal && state.contactFormModal.componentInstance)),
		tap(([action, state]) => {
			const instance = state.contactFormModal.componentInstance as DebtorDetailContactFormComponent;
			instance.allContacts = action.entity;
			// TODO: Find a better way to get the new version of this contact
			instance.contact = action.entity.filter(c => c.email === instance.contact?.email).pop();
			instance.patchFormValues();
		})
	), {dispatch: false});

	/* Create contact form */
	onCreateContactEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateContactRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([action, state]) =>
			this.debtorDetailService.createContact(state.superDebtorId, action.entity).pipe(
				switchMap(_ => [
					featureActions.CreateContactSuccess({entity: action.entity}),
					featureActions.LoadSuperDebtorContactsRequest(state.superDebtorId),
					DunningActionDetailStoreActions.LoadContactsRequest(),
					featureActions.SetSuperDebtorContactFormModal({modal: null})
				]),
				tap(_ => state.contactFormModal.close()),
				catchError(error => of(featureActions.CreateContactFailure({error})))
			)
		)
	));

	/* Update contact form */
	onUpdateContactRequestEffect = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateContactRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([action, state]) =>
			this.debtorDetailService.updateContact(state.superDebtorId, action.entity).pipe(
				switchMap(_ => [
					featureActions.UpdateContactSuccess({entity: action.entity}),
					featureActions.LoadSuperDebtorContactsRequest(state.superDebtorId)
				]),
				tap(_ => state.contactFormModal.close()),
				catchError(error => {
					const actions = [
						featureActions.UpdateContactFailure({error}),
						featureActions.LoadSuperDebtorContactsRequest(state.superDebtorId),
						DunningActionDetailStoreActions.LoadContactsRequest(),
					];
					return of(...actions) as Observable<Action>
				})
			)
		)
	));

	reloadContactsAfterImport$ = createEffect(() => this.ws.ofType(WsEventType.ImportCompletion).pipe(
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		filter(([_, superDebtor]) => !!superDebtor?.id),
		map(([_, superDebtor]) => featureActions.LoadSuperDebtorContactsRequest(superDebtor.id))
	));
}
