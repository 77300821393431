import { Action, createReducer, on } from '@ngrx/store';
import { withEntityReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withEntityReducer<ISuperDebtorPageState>('superDebtorExtradata', featureActions.superDebtorExtradataActions),
	...withEntityReducer<ISuperDebtorPageState>('superDebtorFreedata', featureActions.superDebtorFreedataActions),
	...withEntityReducer<ISuperDebtorPageState>('superDebtorScoringDetails', featureActions.superDebtorScoringActions),
	...withEntityReducer<ISuperDebtorPageState>('superDebtorIdentityForm', featureActions.superDebtorIdentityFormActions),
	...withEntityReducer<ISuperDebtorPageState>('superDebtorCreditLimit', featureActions.superDebtorCreditLimitActions),
	...withEntityReducer<ISuperDebtorPageState>('editedFreeData', featureActions.superDebtorFreeDataActions),
	...withEntityReducer<ISuperDebtorPageState>('sector', featureActions.superDebtorSectorActions),

	on(featureActions.InitSuperDebtorPageSuccess, state => ( {
		...state,
		isLoading: false,
		error: null
	})),

	on(featureActions.InitSuperDebtorPageFailure, (state, action) => ( {
		...state,
		isLoading: false,
		error: action.error
	})),

	on(featureActions.OpenSuperDebtorIdentityForm, state => ({
		...state,
		superDebtorIdentityForm: {
			...state.superDebtorIdentityForm,
			isLoading: false
		}
	})),

	on(featureActions.SetPayingCenterModeToggle, (state, action) => {
		return {
			...state,
			payingCenterMode: action.payingCenterMode,
			accountingDocumentsSelectedIds: [],
			accountingDocumentsAllSelected: false
		};
	}),

	on(featureActions.LoadSuperDebtorIdentitySuccess, state => ({
		...state,
		superDebtorPayingCenter: {
			...state.superDebtorPayingCenter,
			isLoading: false,
			error: null
		}
	})),

	on(featureActions.UpdateSuperDebtorFreeDataRequest, (state, action) => ({
		...state,
		editedFreeData: {
			entity: action.entity,
			isLoading: true,
			error: null
		},
		superDebtorFreedata: {
			...state.superDebtorFreedata,
			isLoading: true,
		}
	})),

	on(featureActions.UpdateSuperDebtorFreeDataSuccess, state => ({
		...state,
		editedFreeData: {
			...state.editedFreeData,
			isLoading: false,
			entity: null,
		},
		superDebtorFreedata: {
			...state.superDebtorFreedata,
			isLoading: false,
		}
	})),

	on(featureActions.UpdateSuperDebtorFreeDataFailure, (state, action) => ({
		...state,
		editedFreeData: {
			...state.editedFreeData,
			isLoading: false,
			error: action.error,
		},
		superDebtorFreedata: {
			...state.superDebtorFreedata,
			isLoading: false,
		}
	})),

	on(featureActions.LoadSuperDebtorExtradataRequest,
		featureActions.LoadSuperDebtorFreedataSuccess,
		featureActions.UpdateSuperDebtorFreeDataRequest,
		featureActions.UpdateSuperDebtorFreeDataSuccess,
		state => state,
	),

);
