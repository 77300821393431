
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import * as featureActions from '../actions';

@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreGlobalEffects {
	constructor(private actions$: Actions) {}

	onDebug$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), {dispatch: false});
}
