
import { Action, ActionReducer } from '@ngrx/store';

import { ISuperDebtorPageState } from '../state';

import { reducer as globalReducer } from './reducer.global';
import { reducer as commReducer } from './reducer.comm';
import { reducer as contactsReducer } from './reducer.contacts';
import { reducer as dunningReducer } from './reducer.dunning';
import { reducer as factoringReducer } from './reducer.factoring';
import { reducer as identityReducer } from './reducer.identity';
import { reducer as networksReducer } from './reducer.networks';



export const reducers: ActionReducer<ISuperDebtorPageState, Action>[] = [
    globalReducer,
    commReducer,
    contactsReducer,
    dunningReducer,
    factoringReducer,
    identityReducer,
    networksReducer,
];
