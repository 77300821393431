import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { IFilterOperator } from '@aston/foundation';
import { DunningActionsFilterSpecService, DunningActionsService } from 'apps/middle/src/app/dunnings-module/services';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SuperDebtorDetailService, SuperDebtorsListFilterSpecService, SuperDebtorsListService } from 'apps/middle/src/app/debtors-module/services';
import { switchMap, map, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';
import { AppStoreActions, catchWithAppError as catchError } from '../../app-store';
import { SuperDebtorPageStore } from '../../../debtors-module/stores/super-debtor-page';


@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreNetworksEffects {

	private actions$ = inject(Actions);
	private store = inject(Store);
	private superDebtorDetailService = inject(SuperDebtorDetailService);
	private superDebtorsListService = inject(SuperDebtorsListService);
	private translateService = inject(TranslateService);
	private filterSpecService = inject(SuperDebtorsListFilterSpecService);
	private dunningActionsService = inject(DunningActionsService);
	private actionsFilterSpecService = inject(DunningActionsFilterSpecService);
	private sdPageStore = inject(SuperDebtorPageStore);

	loadDebtorsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectDebtorsSlice),
			this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([, debtorsSlice, superDebtorId]) => {
			const { filters, paging } = debtorsSlice;
			return this.superDebtorsListService
				.getDebtorsBySuperDebtorId(superDebtorId, { ...paging, filters })
					.pipe(
						map(debtors => featureActions.LoadDebtorsSuccess({list: debtors})),
						catchError(error => of(featureActions.LoadDebtorsFailure({error})))
					);
		})
	));

	loadAttachedDebtorsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAttachedDebtorsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectAttachedDebtorsSlice),
			this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, attachedDebtorsSlice, superDebtorId]) => {
			const { filters: otherFilters, paging } = attachedDebtorsSlice;
			const filters = [
				...otherFilters,
				{
					field: this.filterSpecService.payingCenterIdFilterSpec.field.value,
					operator: IFilterOperator.Equals,
					values: [superDebtorId],
					spec: this.filterSpecService.payingCenterIdFilterSpec
				},
			];
			return this.superDebtorsListService.getAttachedDebtorsBySuperDebtorId({ ...paging, filters })
				.pipe(
					map(list => featureActions.LoadAttachedDebtorsSuccess({list, correlationParams: action.correlationParams})),
					catchError(error =>
						of(featureActions.LoadAttachedDebtorsFailure({error}))
					)
				);
		})
	));

	updateSettingsAction$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAttachedDebtorsSettings),
		map(_ => featureActions.LoadAttachedDebtorsRequest())
	));

	updateFilterEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddAttachedDebtorsFilters, featureActions.RemoveAttachedDebtorsFilters),
		switchMap(_ => [
			featureActions.AttachedDebtorSelectionReset(),
			featureActions.LoadAttachedDebtorsRequest()
		])
	));

	updateDebtorsSettingsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateDebtorsSettings),
		switchMap(() => [
			featureActions.LoadDebtorsRequest(),
			featureActions.LoadFactoringKPIsRequest()
		])
	));

	// be aware that this effect only deals the actions with dunningActionImpactAcknowledgedByUser === true
	UpdateSuperDebtorPayingCenterAssignationRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorPayingCenterAssignationRequest),
		filter(a => !!a.entity.dunningActionImpactAcknowledgedByUser),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, superDebtorIdentity]) => this.superDebtorDetailService.updateSuperDebtorPayingCenter(action.entity.superDebtorId, action.entity.payingCenterFunctionalId).pipe(
			switchMap(() => [
				featureActions.UpdateSuperDebtorPayingCenterAssignationSuccess({entity: action.entity}),
				AppStoreActions.Execute(() => this.sdPageStore.loadIdentity(action.entity.superDebtorId)),
			]),
			catchError(error => {
				const isOwnPayingCenterId: boolean = action.entity.payingCenterFunctionalId === superDebtorIdentity.functionalId;
				if (error.specificError.match(/PayingCenterNotFound/)) {
					error.translated = this.translateService.instant('Errors.ModificationPayingCenterNotFound')
				}
				if (error.specificError.match(/InvalidPayingCenterAssignation/) && isOwnPayingCenterId) {
					error.translated = this.translateService.instant('Errors.ImpossibleToAssignPayingCenterToItself')
				}
				return of(featureActions.UpdateSuperDebtorPayingCenterAssignationFailure({error}))
			})
		))
	));

	// US : https://dev.azure.com/astonitf/LBP/_workitems/edit/19049
	// be aware that this effect only deals the actions with dunningActionImpactAcknowledgedByUser === false
	UpdateSuperDebtorPayingCenterAssignationComputeImpactRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorPayingCenterAssignationRequest),
		filter(a => !a.entity.dunningActionImpactAcknowledgedByUser),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, superDebtorIdentity]) => {

			const updateSuperDebtorPayingCenterAssignationRequest = featureActions.UpdateSuperDebtorPayingCenterAssignationRequest({
				superDebtorId: action.entity.superDebtorId,
				payingCenterFunctionalId: action.entity.payingCenterFunctionalId,
				dunningActionImpactAcknowledgedByUser: true
			});

			//	First we check if some DA will be impacted :
			//    ==> if not : request the actual update by setting dunningActionImpactAcknowledgedByUser to true
			//    ==> if yes : ask user confirmation and then request the actual update by setting dunningActionImpactAcknowledgedByUser to true
			const withCompleted = false;
			const params = {
				filters: [{
					field: 'superDebtorFunctionalId', operator: IFilterOperator.Equals,
					values: [superDebtorIdentity.functionalId],
					spec: this.actionsFilterSpecService.superDebtorFunctionalIdFieldFilterSpec
				}, {
					field: 'payingCenterFunctionalId', operator: IFilterOperator.Equals,
					values: [superDebtorIdentity.payingCenterFunctionalId],
					spec: this.actionsFilterSpecService.payingCenterFunctionalIdFieldFilterSpec
				}],
				page: 1,
				pageSize: 1 // no need to get a lot of items, only one will be enough as we only use the totalItemCount
			};

			return this.dunningActionsService.getDunningActions(withCompleted, params).pipe(
				switchMap((result) => [
					result.paging.totalItemCount <= 0 ?
						updateSuperDebtorPayingCenterAssignationRequest :
						AppStoreActions.OpenConfirmationModal(
							{textsKey: 'SuperDebtors.PayingCenterEdition.PayingCenterHaveImpactOnDunningActionsConfirmation.'},
							updateSuperDebtorPayingCenterAssignationRequest,
							featureActions.UpdateSuperDebtorPayingCenterAssignationSuccess({
								entity: {
									superDebtorId: action.entity.superDebtorId,
									payingCenterFunctionalId: superDebtorIdentity.payingCenterFunctionalId,
									dunningActionImpactAcknowledgedByUser: false
								}
							})
						)
				]),
				catchError(error => of(featureActions.UpdateSuperDebtorPayingCenterAssignationFailure({error})))
			);
		})
	));
}
