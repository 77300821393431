import { Action, createReducer } from '@ngrx/store';
import { withEntityReducer, withListReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withListReducer<ISuperDebtorPageState>('factoringEvents', featureActions.factoringEventsActions),
	...withEntityReducer<ISuperDebtorPageState>('factoringKPIs', featureActions.factoringKPIsActions),

);
