import { Action, createReducer, on } from '@ngrx/store';
import { withEntityReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withEntityReducer<ISuperDebtorPageState>('superDebtorContacts', featureActions.superDebtorContactsActions),
	...withEntityReducer<ISuperDebtorPageState>('contactForm', featureActions.contactActions),

	on(featureActions.OpenSuperDebtorContactForm, state => ( {
		...state,
		contactForm: {
			...state.contactForm,
			isLoading: false,
			error: null,
		}
	})),

	on(featureActions.SetSuperDebtorContactFormModal, (state, action) => ({
		...state,
		contactFormModal: action.modal
	})),
);
