

import { IDebtorsList, ISuperDebtorDunningCase, ISuperDebtorDunningDiagnostic, IDebtorContactForm, ISuperDebtorIdentityForm, ISuperDebtorsList, ISuperDebtorsListFilter, ISuperDebtorScoringDetails } from 'apps/middle/src/app/debtors-module/models';
import { IAccountingItemList, IAccountingListFilter, IFactoringEventList, IFactoringEventsListFilter, IFactoringKPI } from 'apps/middle/src/app/accounting-module/models';
import { AccountingListSortProp, FactoringEventsListSortProp } from 'apps/middle/src/app/accounting-module/enums';
import { IDebtorContact } from 'apps/middle/src/app/debtors-module/models/debtor-contact';
import { DebtorsListSortProp, SuperDebtorsListSortProp } from 'apps/middle/src/app/debtors-module/enums';
import { CommentsListSortProp, EmailsListSortProp, LettersListSortProp } from 'apps/middle/src/app/shared-module/enums';
import { IFilter, SectorType } from '@aston/foundation';
import { ICommentItemList, IEmailList, IExtraDataAny, ILetterList } from 'apps/middle/src/app/shared-module/models';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IFreeDataChange } from 'apps/middle/src/app/shared-module/models/api';
import { ManagedDocumentListSortProp } from '@aston/files-manager';

import { AppConstants } from '../../app.constants';
import { createEntitySlice, createListSlice, IStoreEntityStateSlice, IStoreListStateSlice } from '../functions';

import { FeatureName } from './feature';

export const DEFAULT_FILES_LIST_STATE = { sorts: [{ prop: ManagedDocumentListSortProp.DepositDate, isDesc: true}] }

export interface ISuperDebtorPageState {

	isLoading: boolean;
	error: Error;

	payingCenterMode: boolean;

	superDebtorId: number;
	superDebtorExtradata: IStoreEntityStateSlice<IExtraDataAny[]>;
	superDebtorFreedata: IStoreEntityStateSlice<IExtraDataAny[]>;
	superDebtorScoringDetails: IStoreEntityStateSlice<ISuperDebtorScoringDetails>;

	superDebtorIdentityForm: IStoreEntityStateSlice<ISuperDebtorIdentityForm>;


	dunningAccountingDocuments: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>;
	dunningAccountingDocumentsAllSelected: boolean;
	dunningAccountingDocumentsIdsList: IStoreEntityStateSlice<number[]>;
	dunningAccountingDocumentsSelectedIds: number[];

	factoringEvents: IStoreListStateSlice<IFactoringEventList, FactoringEventsListSortProp, IFactoringEventsListFilter>;
	factoringKPIs: IStoreEntityStateSlice<IFactoringKPI>;

	superDebtorContacts: IStoreEntityStateSlice<IDebtorContact[]>;

	debtors: IStoreListStateSlice<IDebtorsList, DebtorsListSortProp, null>;

	attachedDebtors: IStoreListStateSlice<ISuperDebtorsList, SuperDebtorsListSortProp,  ISuperDebtorsListFilter>;

	superDebtorDunningCaseSTDId: number;
	superDebtorDunningCase: IStoreEntityStateSlice<ISuperDebtorDunningCase[]>;

	superDebtorDunningDiagnosticFormIsOpened: boolean;
	superDebtorDunningDiagnosticForm: IStoreEntityStateSlice<ISuperDebtorDunningDiagnostic>;

	mails: IStoreListStateSlice<IEmailList, any, IFilter>;
	letters: IStoreListStateSlice<ILetterList, any, IFilter>;
	comments: IStoreListStateSlice<ICommentItemList, any, IFilter>;

	contactForm: IStoreEntityStateSlice<IDebtorContactForm>;
	contactFormModal: NgbModalRef;
	dunningActionsCountNotCompletedHoldingSelectedAccountingDocuments: IStoreEntityStateSlice<number>;

	superDebtorPayingCenter: IStoreEntityStateSlice<string>;

	superDebtorCreditLimit: IStoreEntityStateSlice<number>;

	editedFreeData: IStoreEntityStateSlice<{ superDebtorId: number, freedata: IFreeDataChange }>;

	sector: IStoreEntityStateSlice<SectorType>;
}

export const initialState: ISuperDebtorPageState = {
	// Base
	superDebtorId: null,
	isLoading: false,
	error: null,

	payingCenterMode: false,

	superDebtorIdentityForm: createEntitySlice(),
	superDebtorExtradata: createEntitySlice(),
	superDebtorFreedata: createEntitySlice(),
	superDebtorScoringDetails: createEntitySlice(),


	// Dunning accounting
	dunningAccountingDocuments: createListSlice({prop: AccountingListSortProp.DueDate, isDesc: false}, 5),
	dunningAccountingDocumentsAllSelected: false,
	dunningAccountingDocumentsIdsList: createEntitySlice({entity: []}),
	dunningAccountingDocumentsSelectedIds: [],

	// Factoring
	factoringEvents: createListSlice({prop: FactoringEventsListSortProp.AccountingDate, isDesc: true},
		AppConstants.LIST_PAGE_SIZE_IGNORED, [], `${FeatureName}.factoringEvents`),
	factoringKPIs: createEntitySlice(),

	// Contacts
	superDebtorContacts: createEntitySlice({entity: []}),

	superDebtorDunningCase: createEntitySlice({entity: []}),
	superDebtorDunningCaseSTDId: null,

	// Debtors
	debtors: createListSlice({prop: DebtorsListSortProp.TOTAL_OUTSTANDING_AMOUNT, isDesc: false}, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.debtors`),

	// Attached debtors
	attachedDebtors: createListSlice({prop: SuperDebtorsListSortProp.TOTAL_OUTSTANDING_AMOUNT, isDesc: false}, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.attachedDebtors`),

	// Dunning Diagnostic
	superDebtorDunningDiagnosticForm: createEntitySlice(),
	superDebtorDunningDiagnosticFormIsOpened: false,

	mails: createListSlice({prop: EmailsListSortProp.SEND_DATE, isDesc: true}, 5),
	letters: createListSlice({prop: LettersListSortProp.SEND_DATE, isDesc: true}, 5),
	comments: createListSlice({prop: CommentsListSortProp.CREATION_DATE, isDesc: true}, 5),

	contactFormModal: null,
	contactForm: createEntitySlice(),

	dunningActionsCountNotCompletedHoldingSelectedAccountingDocuments: createEntitySlice(),

	superDebtorPayingCenter: createEntitySlice(),

	superDebtorCreditLimit: createEntitySlice(),

	editedFreeData: createEntitySlice(),

	sector: createEntitySlice({entity: SectorType.None}),
};
