<form
	[formGroup]="form"
	[class.block-loader]="loader.isLoading$ | async"
	(submit)="onSubmit()"
	autocomplete="off">

	<div class="modal-header">
		<h2 class="modal-title" translate="SuperDebtors.TemporaryCreditLimitModalTitle">
		</h2>
		<button type="button" class="modal-button-close fal fa-times" (click)="onClose()"></button>
	</div>

	<loader-spinner [model]="loader"/>

	<div class="modal-body">
		<div class="form-group">
			<label class="form-label form-label-required" translate="Forms.CommonLabels.TemporaryCreditLimitLabel"></label>
			<input type="number" autocomplete="off" class="form-control form-control-alt" id="field-temporaryCreditLimit"
					formControlName="temporaryCreditLimit" placeholder="{{'Forms.CommonLabels.TemporaryCreditLimitLabel' | translate}}"
					[class.is-invalid]="temporaryCreditLimitField.dirty && temporaryCreditLimitField.invalid"
			/>
			<form-control-feedback [field]="temporaryCreditLimitField"/>
		</div>
		<div class="form-group">
			<label class="form-label form-label-required" translate="Forms.CommonLabels.TemporaryCreditLimitEndLabel"></label>
			<form-datepicker
				formControlName="temporaryCreditLimitEnd"
				inputClass="form-control-alt"
				[forcePropagation]="temporaryCreditLimitEndField.dirty"
				[class.is-invalid]="temporaryCreditLimitEndField.dirty && temporaryCreditLimitEndField.invalid"
				[control]="temporaryCreditLimitEndField"
				controlId="field-temporaryCreditLimitEnd"
				[minDate]="today"
			/>
			<form-control-feedback [field]="temporaryCreditLimitEndField"/>
		</div>

		<div class="form-text" translate="Forms.RequiredFields"></div>
	</div>

	<div class="modal-footer">
		<button type="button"
			(click)="onCancel()"
			class="btn btn-default"
			translate="Actions.Cancel" data-cy="Cancel"></button>

		<button type="button"
			(click)="onSubmit()"
			class="btn btn-primary"
			[disabled]="isFormDisabled"
			translate="Actions.Save" data-cy="Save"></button>
	</div>
</form>
