import { Action, createReducer, on } from '@ngrx/store';
import { withEntityReducer, withListReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withListReducer<ISuperDebtorPageState>('debtors', featureActions.superDebtorDebtorsActions),
	...withListReducer<ISuperDebtorPageState>('attachedDebtors', featureActions.superDebtorAttachedDebtorsActions),
	...withEntityReducer<ISuperDebtorPageState>('superDebtorPayingCenter', featureActions.superDebtorPayingCenterAssignationActions),

	on(featureActions.AttachedDebtorSelectionReset, state => {
		const list = { ...state.attachedDebtors };

		list.list.items.forEach(item => {
			item.isSelected = false;
		});

		return {
			...state,
			list,
			allSelected: false,
			selectedIds: []
		};
	}),

);
