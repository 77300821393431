import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Domain, FilesManagerStoreActions, FilesManagerStoreSelectors } from "@aston/files-manager";
import { auditTime, catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { HttpErrorMessage } from "@aston/foundation";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { saveAs } from 'file-saver';

import * as AppStoreActions from "../../app-store/actions";
import { AppConstants } from "../../../app.constants";
import { DocumentFileService } from "../../../shared-module/services";
import { SuperDebtorDetailService } from "../../../debtors-module/services";
import { DEFAULT_FILES_LIST_STATE } from "../state";
import * as featureSelectors from "../selectors";

@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreFilesEffects {
	constructor(
		private store: Store,
		private actions$: Actions,
		private translateService: TranslateService,
		private documentFileService: DocumentFileService,
		private superDebtorDetailService: SuperDebtorDetailService,
	) { }

	loadDocumentsRequest$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.LoadFilesRequest),
		filter(action => action.domain === Domain.SuperDebtor),
		switchMap(action => {
			const state = action.state || DEFAULT_FILES_LIST_STATE
			return this.superDebtorDetailService.loadLinkedDocuments(action.entityId, state).pipe(
				map(list => FilesManagerStoreActions.LoadFilesSuccess({ domain: action.domain, entity: { list, state } })),
				catchError(error => of(FilesManagerStoreActions.LoadFilesFailure({ domain: action.domain, error })))
			)
		})
	))

	addDocumentRequest$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.AddFileRequest),
		filter(action => action.domain === Domain.SuperDebtor),
		mergeMap(({entityId, domain, entity}) =>
			this.documentFileService.checkFile(entity, AppConstants.FILE_UPLOADER_CONFIGS.LINKED_DOCUMENTS).pipe(
			switchMap(_ => this.superDebtorDetailService.addDocument(entityId, entity)),
			map(_ => FilesManagerStoreActions.AddFileSuccess({ entityId, domain, entity })),
			catchError((err: HttpErrorMessage) => {
				const error = err.key?.match(/ValidationFailure/) ?
					err.resetKey('InvalidFile').translate(this.translateService) :
					err;
				return of(
					FilesManagerStoreActions.AddFileFailure({ domain, error }),
					AppStoreActions.ToastError(error.toString()),
				)
			})
		))
	));

	onReloadDocuments$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.AddFileSuccess),
		filter(action => action.domain === Domain.SuperDebtor),
		auditTime(100),
		map(({entityId, domain}) => FilesManagerStoreActions.LoadFilesRequest({ entityId, domain }))
	));

	onDeleteDocument$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.DeleteFileConfirm),
		filter(action => action.domain === Domain.SuperDebtor),
		map(action => AppStoreActions.OpenConfirmationModal(
			confirmationModalOptions,
			FilesManagerStoreActions.DeleteFileRequest({
				entityId: action.entityId,
				domain: action.domain,
				entity: action.entity
			})
		))
	));

	onDeleteDocumentRequest$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.DeleteFileRequest),
		filter(action => action.domain === Domain.SuperDebtor),
		switchMap(action => this.superDebtorDetailService.deleteLinkedDocument(action.entityId, action.entity).pipe(
			switchMap(_ => [
				FilesManagerStoreActions.DeleteFileSuccess({ domain: action.domain, entity: null }),
				FilesManagerStoreActions.LoadFilesRequest({
					entityId: action.entityId,
					domain: action.domain
				}),
			]),
			catchError(error => of(FilesManagerStoreActions.DeleteFileFailure({ domain: action.domain, error })))
		))
	));

	onDownloadMultipleDocuments$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.DownloadMultipleDocuments),
		filter(action => action.domain === Domain.SuperDebtor),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, sid]) => this.superDebtorDetailService.downloadMultipleDocuments(sid, action.ids).pipe(
			map(response => {
				saveAs(response.blob, response.fileName);
				return AppStoreActions.Noop()
			}),
			catchError(_ => of(AppStoreActions.ToastError('Errors.RetryableError')))
		))
	));

	onFileSelectionReset$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.SelectionReset),
		filter(action => action.domain === Domain.SuperDebtor),
		withLatestFrom(this.store.select(FilesManagerStoreSelectors.selectFilesOfDomain(Domain.SuperDebtor))),
		map(([action, state]) => {
			const entity = {
				...state,
				list: {
					...state.list,
					items: state.list.items.map(e => ({ ...e, isSelected: action.selectAll }))
				}
			}
			return FilesManagerStoreActions.LoadFilesSuccess({ domain: action.domain, entity })
		})
	));

	onFileSelectionAdd$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.SelectionAdd),
		filter(action => action.domain === Domain.SuperDebtor),
		withLatestFrom(this.store.select(FilesManagerStoreSelectors.selectFilesOfDomain(Domain.SuperDebtor))),
		map(([action, state]) => {
			const items = state.list.items.slice();
			action.ids.forEach(i => state.list.items.find(f => f.id === i).isSelected = true)
			const entity = { ...state, list: { ...state.list, items }}
			return FilesManagerStoreActions.LoadFilesSuccess({ domain: action.domain, entity })
		})
	));

	onFileSelectionRemove$ = createEffect(() => this.actions$.pipe(
		ofType(FilesManagerStoreActions.SelectionRemove),
		filter(action => action.domain === Domain.SuperDebtor),
		withLatestFrom(this.store.select(FilesManagerStoreSelectors.selectFilesOfDomain(Domain.SuperDebtor))),
		map(([action, state]) => {
			const items = state.list.items.slice();
			action.ids.forEach(i => state.list.items.find(f => f.id === i).isSelected = false)
			const entity = { ...state, list: { ...state.list, items }}
			return FilesManagerStoreActions.LoadFilesSuccess({ domain: action.domain, entity })
		})
	));

}

const confirmationModalOptions = {
	textsKey: `SuperDebtors.DeleteLinkedDocument.`,
	confirmBtnText: 'Actions.Confirm',
	cancelBtnText: 'Actions.Cancel',
};
