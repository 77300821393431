
import { Component, OnInit, Output, EventEmitter, Input, inject } from '@angular/core';
import { UnsubscriberClass, IAction, ActionType, CustomValidators, LoaderSpinnerComponent, FormControlFeedbackComponent, DatepickerComponent } from '@aston/foundation';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

import { ISuperDebtorsBulkEditFormCreditLimitValidator } from '../../../shared-module/enums/validators/super-debtors-bulk-edit.validators';
import { ISuperDebtorTemporaryCreditLimitFormPayload, ISuperDebtorsBulkEditForm } from '../../models';
import { SuperDebtorsListStore } from '../../stores';

export type FormValidatorFieldsType = ISuperDebtorsBulkEditFormCreditLimitValidator['fields'];

@Component({
	selector: 'super-debtor-temporary-credit-limit-form',
	templateUrl: './super-debtor-temporary-credit-limit-form.component.html',
	standalone: true,
	imports: [ReactiveFormsModule, AsyncPipe, TranslateModule, LoaderSpinnerComponent, FormControlFeedbackComponent, DatepickerComponent]
})
export class SuperDebtorTemporaryCreditLimitFormComponent extends UnsubscriberClass implements OnInit, ISuperDebtorsBulkEditForm {
	sdListStore = inject(SuperDebtorsListStore)
	formBuilder = inject(UntypedFormBuilder)

	@Input() currentTemporaryCreditLimit: Partial<ISuperDebtorTemporaryCreditLimitFormPayload>;

	@Output() resultEvent: EventEmitter<IAction> = new EventEmitter();

	form: UntypedFormGroup;
	today = new Date();

	loader = this.sdListStore.formLoader()

	ngOnInit() {
		this.buildForm();
	}

	buildForm() {
		this.form = this.formBuilder.group({});
		this.form.addControl('temporaryCreditLimit', new UntypedFormControl(null, [Validators.required, CustomValidators.decimalNumber(2), CustomValidators.greaterThanOrEqual(0)]));
		this.form.addControl('temporaryCreditLimitEnd', new UntypedFormControl(null, [Validators.required, CustomValidators.validDate, CustomValidators.afterNow(true)]));

		this.form.patchValue(this.currentTemporaryCreditLimit);
	}

	get isFormDisabled(): boolean {
		return this.temporaryCreditLimitField.invalid || this.temporaryCreditLimitEndField.invalid;
	}

	get temporaryCreditLimitField () {
		return this.form.get('temporaryCreditLimit');
	}

	get temporaryCreditLimitEndField () {
		return this.form.get('temporaryCreditLimitEnd');
	}

	onSubmit() {
		this.resultEvent.emit({ type: ActionType.SUBMIT, value: this.form.value });
	}

	onClose() {
		this.resultEvent.emit({ type: ActionType.CLOSE });
	}

	onCancel() {
		this.resultEvent.emit({ type: ActionType.CANCEL });
	}
}
