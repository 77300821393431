

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { mergeReducers } from '@aston/foundation';

import { effects } from './effects';
import { reducers } from './reducers';
import { FeatureName } from './feature';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, mergeReducers(...reducers)),
		EffectsModule.forFeature(effects)
	]
})

export class SuperDebtorPageStoreModule { }
