import { Action, createReducer, on } from '@ngrx/store';
import { withListReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withListReducer<ISuperDebtorPageState>('mails', featureActions.superDebtorMailsActions),
	...withListReducer<ISuperDebtorPageState>('comments', featureActions.superDebtorCommentsActions),
	...withListReducer<ISuperDebtorPageState>('letters', featureActions.superDebtorLettersActions),

	on(featureActions.AddSuperDebtorCommentRequest, (state, action) => ({
		...state,
		comments: {
			...state.comments,
			list: {
				...state.comments.list,
				items: [
					action.comment,
					...state.comments.list.items
				]
			}
		}
	})),
);
