
import { Type } from '@angular/core';

import { SuperDebtorPageStoreGlobalEffects } from './effects.global';
import { SuperDebtorPageStoreDunningEffects } from './effects.dunning';
import { SuperDebtorPageStoreContactsEffects } from './effects.contacts';
import { SuperDebtorPageStoreNetworksEffects } from './effects.networks';
import { SuperDebtorPageStoreIdentityEffects } from './effects.identity';
import { SuperDebtorPageStoreFactoringEffects } from './effects.factoring';
import { SuperDebtorPageStoreCommunicationsEffects } from './effects.comm';
import { SuperDebtorPageStoreFilesEffects } from './effects.files';

export const effects: Type<any>[] = [
    SuperDebtorPageStoreGlobalEffects,
    SuperDebtorPageStoreDunningEffects,
    SuperDebtorPageStoreContactsEffects,
    SuperDebtorPageStoreNetworksEffects,
    SuperDebtorPageStoreIdentityEffects,
    SuperDebtorPageStoreFactoringEffects,
    SuperDebtorPageStoreCommunicationsEffects,
    SuperDebtorPageStoreFilesEffects,
];
