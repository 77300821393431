
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, ValidationErrors, Validators, ReactiveFormsModule } from '@angular/forms';
import { Loader, ObjectPropertyNamesHolder, extractFormControlNames, UnsubscriberClass, ValidationService, ValidationServiceHelper, ICountryListItemTranslated, IFilterValue, LoaderSpinnerComponent, FormControlFeedbackComponent } from '@aston/foundation';
import { NgbActiveModal, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { auditTime, distinctUntilChanged, first, map, switchMap, takeUntil, tap, take } from 'rxjs/operators';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { LegalIdentifierStatus } from 'apps/middle/src/app/shared-module/enums';
import { MiddleDomainValidators } from 'apps/middle/src/app/shared-module/enums/validators';
import { ISuperDebtorIdentityFormValidator } from 'apps/middle/src/app/shared-module/enums/validators/super-debtors-identity-edit.validators';
import { INationalIdentifierResult } from 'apps/middle/src/app/shared-module/models/api';
import { CompanyInformationService } from 'apps/middle/src/app/shared-module/services';
import { FeatureFlagDirective, FeatureFlagsService } from '@aston/feature-flags';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { SuperDebtorsListService } from '../../services';
import { ISuperDebtorIdentityForm, ISuperDebtorIdentity } from '../../models';

export type FormValidatorFieldsType = ISuperDebtorIdentityFormValidator['fields'];
export const formControlNames = (name: keyof ISuperDebtorIdentityForm) => name;

@Component({
	selector: 'super-debtor-detail-identity-form',
	templateUrl: './super-debtor-detail-identity-form.component.html',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FeatureFlagDirective,
		AsyncPipe,
		TranslateModule,
		NgbAlertModule,
		NgxMaskDirective,
		LoaderSpinnerComponent,
		FormControlFeedbackComponent
	]
})
export class SuperDebtorDetailIdentityFormComponent extends UnsubscriberClass implements OnInit {
	@Input() superDebtor: ISuperDebtorIdentity;
	@Input() loader = Loader.empty();
	@Input() countries: ICountryListItemTranslated[];
	@Input() languages: IFilterValue[];
	@Input() isImportMenuDisabled: boolean;
	@Input() manualInvoicingEnabled: boolean;
	@Output() cancel: EventEmitter<boolean> = new EventEmitter();
	@Output() submitForm: EventEmitter<ISuperDebtorIdentityForm> = new EventEmitter();

	validationHelper: ValidationServiceHelper;
	rulesetFields: FormValidatorFieldsType;

	form: UntypedFormGroup;
	formControlNames: ObjectPropertyNamesHolder<ISuperDebtorIdentityForm>;

	legalIdentifierMask = AppConstants.SIRET_MASK;

	showInvoicingAddress = false;

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: UntypedFormBuilder,
		private sdService: SuperDebtorsListService,
		private ffs: FeatureFlagsService,
		private companyInformationService: CompanyInformationService,
		private validationService: ValidationService,
	) {
		super();
	}

	ngOnInit() {
		this.form = this.formBuilder.group({});

		this.form.addControl(formControlNames('companyName'), new UntypedFormControl());
		this.form.addControl(formControlNames('functionalId'), new UntypedFormControl());
		this.form.addControl(formControlNames('legalIdentifier'), new UntypedFormControl());
		this.form.addControl(formControlNames('email'), new UntypedFormControl());
		this.form.addControl(formControlNames('language'), new UntypedFormControl());
		this.form.addControl(formControlNames('phoneNumber'), new UntypedFormControl());
		this.form.addControl(formControlNames('vatNumber'), new UntypedFormControl());
		this.form.addControl(formControlNames('street'), new UntypedFormControl());
		this.form.addControl(formControlNames('zipCode'), new UntypedFormControl());
		this.form.addControl(formControlNames('city'), new UntypedFormControl());
		this.form.addControl(formControlNames('countryCode'), new UntypedFormControl());
		this.form.addControl(formControlNames('billingStreet'), new UntypedFormControl());
		this.form.addControl(formControlNames('billingZipCode'), new UntypedFormControl());
		this.form.addControl(formControlNames('billingCity'), new UntypedFormControl());
		this.form.addControl(formControlNames('billingCountryCode'), new UntypedFormControl());

		this.formControlNames = extractFormControlNames<ISuperDebtorIdentityForm>(this.form);

		this.validationService.getHelper(MiddleDomainValidators.SuperDebtorIdentityFormValidator).pipe(
			tap(([helper, fields, _]) => {
				this.validationHelper = helper;
				this.rulesetFields = fields as FormValidatorFieldsType;
			}),
			takeUntil(this.destroySubscriptions$),
		).subscribe(_ => {
			this.setFormValidators();
		});

		if (this.superDebtor) {
			this.form.patchValue({
				companyName: this.superDebtor.name,
				functionalId: this.superDebtor.functionalId,
				legalIdentifier: this.superDebtor.legalIdentifier,
				email: this.superDebtor.email,
				phoneNumber: this.superDebtor.landlinePhoneNumber,
				language: this.superDebtor.language,
				vatNumber: this.superDebtor.vatNumber,
				street: this.superDebtor.physicalAddress?.street,
				zipCode: this.superDebtor.physicalAddress?.zipCode,
				city: this.superDebtor.physicalAddress?.city,
				countryCode: this.superDebtor.physicalAddress?.countryCode,
				billingStreet: this.superDebtor.billingAddress?.street,
				billingZipCode: this.superDebtor.billingAddress?.zipCode,
				billingCity: this.superDebtor.billingAddress?.city,
				billingCountryCode: this.superDebtor.billingAddress?.countryCode
			});
		}
	}

	get companyNameField() {
		return this.form.get(formControlNames('companyName'));
	}

	get functionalIdField() {
		return this.form.get(formControlNames('functionalId'));
	}

	get legalIdentifierField() {
		return this.form.get(formControlNames('legalIdentifier'));
	}

	get emailField() {
		return this.form.get(formControlNames('email'));
	}

	get languageField() {
		return this.form.get(formControlNames('language'));
	}

	get phoneNumberField() {
		return this.form.get(formControlNames('phoneNumber'));
	}

	get vatNumberField() {
		return this.form.get(formControlNames('vatNumber'));
	}

	get streetField() {
		return this.form.get(formControlNames('street'));
	}

	get zipCodeField() {
		return this.form.get(formControlNames('zipCode'));
	}

	get cityField() {
		return this.form.get(formControlNames('city'));
	}

	get countryCodeField() {
		return this.form.get(formControlNames('countryCode'));
	}

	get billingStreetField() {
		return this.form.get(formControlNames('billingStreet'));
	}

	get billingZipCodeField() {
		return this.form.get(formControlNames('billingZipCode'));
	}

	get billingCityField() {
		return this.form.get(formControlNames('billingCity'));
	}

	get billingCountryCodeField() {
		return this.form.get(formControlNames('billingCountryCode'));
	}

	get billingAddressRequired() {
		return this.manualInvoicingEnabled && this.hasStartedFillingBillingFieldsValidator() !== null;
	}

	setFormValidators() {
		this.validationHelper.control(this.functionalIdField, this.rulesetFields.FunctionalId);
		this.validationHelper.control(this.companyNameField, this.rulesetFields.Name);
		this.validationHelper.control(this.legalIdentifierField, this.rulesetFields.LegalIdentifier);
		this.validationHelper.control(this.emailField, this.rulesetFields.Email);
		this.validationHelper.control(this.phoneNumberField, this.rulesetFields.PhoneNumber);
		this.validationHelper.control(this.vatNumberField, this.rulesetFields.VatNumber);
		this.validationHelper.control(this.streetField, this.rulesetFields.Street);
		this.validationHelper.control(this.zipCodeField, this.rulesetFields.ZipCode);
		this.validationHelper.control(this.cityField, this.rulesetFields.City);
		this.validationHelper.control(this.countryCodeField, this.rulesetFields.Country);
		this.validationHelper.control(this.billingStreetField, this.rulesetFields.BillingStreet);
		this.validationHelper.control(this.billingZipCodeField, this.rulesetFields.BillingZipCode);
		this.validationHelper.control(this.billingCityField, this.rulesetFields.BillingCity);
		this.validationHelper.control(this.billingCountryCodeField, this.rulesetFields.BillingCountry);

		if (this.ffs.isFeatureEnabled('superDebtorLegalIdMask')) {
			this.legalIdentifierField.setAsyncValidators(this.nationalIdValidator.bind(this));
		}
		else {
			this.legalIdentifierField.setValidators(Validators.maxLength(150));
		}

		if (this.isImportMenuDisabled) {
			this.functionalIdField.setValidators(Validators.required);
		}

		this.functionalIdField.setAsyncValidators(this.nameValidator.bind(this));
		this.companyNameField.addValidators(Validators.required);

		if (this.manualInvoicingEnabled) {
			this.streetField.addValidators(Validators.required);
			this.zipCodeField.addValidators(Validators.required);
			this.cityField.addValidators(Validators.required);
			this.countryCodeField.addValidators(Validators.required);
			this.emailField.addValidators(Validators.required);

			this.form.addValidators(this.hasStartedFillingBillingFieldsValidator.bind(this))
		}
	}

	nameValidator(control: UntypedFormControl): Observable<ValidationErrors | null> {
		if (!control.value || control.value === this.superDebtor.functionalId) return of(null);

		return this.sdService.superDebtorExists(control.value).pipe(
			takeUntil(this.destroySubscriptions$),
			take(1),
			map(exists => exists ? { alreadyUsedIdentifier: true } : null)
		)
	}

	nationalIdValidator(control: UntypedFormControl): Observable<ValidationErrors | null> {
		const success = {legalIdentifierValidationStatus: LegalIdentifierStatus.Success} as unknown as INationalIdentifierResult;
		return control.valueChanges.pipe(
			auditTime(400),
			distinctUntilChanged(),
			switchMap(value => {
				if (value === "" || value === this.superDebtor.legalIdentifier) {
					return of(success);
				}
				else {
					return this.companyInformationService.checkWithNationalIdentifier(value);
				}
			}),
			map(result => {
				if (result.legalIdentifierValidationStatus === LegalIdentifierStatus.Success) {
					return null;
				}
				else if (result.legalIdentifierValidationStatus === LegalIdentifierStatus.Failure) {
					return {wrongIdentifier: true};
				}
				else {
					return {alreadyUsedIdentifier: true}
				}
			}),
			first());
	}

	hasStartedFillingBillingFieldsValidator() {
		const identity = this.form.value;

		// none filled
		if (!identity.billingStreet
			&& !identity.billingZipCode
			&& !identity.billingCity
			&& !identity.billingCountryCode
		) return null;

		// all filled
		if (identity.billingStreet
			&& identity.billingZipCode
			&& identity.billingCity
			&& identity.billingCountryCode
		) return undefined;

		return {required: true};
	}

	getModelFromForm(): ISuperDebtorIdentityForm {
		return {
			id: this.superDebtor.id,
			legalIdentifier: this.legalIdentifierField.value,
			email: this.emailField.value,
			language: this.languageField.value,
			phoneNumber: this.phoneNumberField.value,
			street: this.streetField.value,
			zipCode: this.zipCodeField.value,
			city: this.cityField.value,
			countryCode: this.countryCodeField.value,
			vatNumber: this.vatNumberField.value,
			billingStreet: this.billingStreetField.value,
			billingZipCode: this.billingZipCodeField.value,
			billingCity: this.billingCityField.value,
			billingCountryCode: this.billingCountryCodeField.value,

			functionalId: this.isImportMenuDisabled ? this.functionalIdField.value : null,
			companyName: this.isImportMenuDisabled ? this.companyNameField.value : null,
		};
	}

	onSubmit() {
		this.submitForm.emit(this.getModelFromForm());
	}

}
