import { ChangeDetectionStrategy, Component, computed, inject, OnInit} from '@angular/core';
import { BookmarkComponent, BookmarksStore } from '@aston/foundation';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { injectFeaturesStore } from '../../../factor-features-module/stores/factor-features';

import { MenuComponent } from './menu.component';

@Component({
	selector: '[layout-menu-acc]',
	templateUrl: './menu-acc.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, RouterLink, RouterLinkActive, BookmarkComponent]
})
export class MenuACCComponent extends MenuComponent implements OnInit {
	protected bookmarksStore = inject(BookmarksStore);
	store = injectFeaturesStore()
	
	isImportMenuDisabled = computed(() => this.store.isImportMenuDisabled());
}
