import { tap, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { mapToUserAvatar } from '@aston/user-badge';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';

import { DunningContactsFilterSpecService } from '../../../dunning-settings-module/services/dunning-contacts-filter-spec.service';
import { SuperDebtorDetailService } from '../../../debtors-module/services/super-debtor-detail.service';
import { PdfViewerComponent } from '../../../shared-module/components/pdf-viewer/pdf-viewer.component';
import { DocumentFileService } from '../../../shared-module/services/document-file.service';
import { SuperDebtorPageStore } from '../../../debtors-module/stores/super-debtor-page';
import { MailWriterStore } from '../../../shared-module/stores/mail-writer.store';
import { ThirdPartyContactsService } from '../../../shared-module/services';
import { mapToDisplayedContact } from '../../../dunnings-module/functions';
import { catchWithAppError as catchError } from '../../app-store/utils';
import * as AppStoreSelectors from '../../app-store/selectors';
import { ICommentItem } from '../../../shared-module/models';
import * as AppStoreActions from '../../app-store/actions';
import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';


@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreCommunicationsEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private i18n: TranslateService,
		private sdPageStore: SuperDebtorPageStore,
		private tpContactsService: ThirdPartyContactsService,
		private superDebtorDetailService: SuperDebtorDetailService,
		private contactsFiltersSpec: DunningContactsFilterSpecService,
		private modalService: NgbModal,
		private mailWriter: MailWriterStore,
		private documentFileService: DocumentFileService) {
	}

	sendMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendSuperDebtorMailRequest),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, superDebtorIdentity]) => this.superDebtorDetailService.sendEmail(superDebtorIdentity.id,
			action.entity).pipe(
			switchMap(_ => [
				featureActions.SendSuperDebtorMailSuccess({entity: action.entity, correlationParams: action.correlationParams}),
				AppStoreActions.Execute(() => this.mailWriter.onMailSent()),
				featureActions.LoadSuperDebtorMailsRequest()
			]),
			catchError(error => of(
				featureActions.SendSuperDebtorMailFailure({error}),
			))
		))
	));

	loadMails = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorMailsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectMailsSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, mails, superDebtorIdentity]) => {
			const { filters, paging } = mails;
			return this.superDebtorDetailService.getSentMails(superDebtorIdentity.id, { ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorMailsSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorMailsFailure({error})))
			);
		})
	));

	showMoreMails = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ShowMoreSuperDebtorMailsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectMailsSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, mails, superDebtorIdentity]) => {
			const { filters, paging } = mails;
			paging.pageSize = mails.paging.pageSize + 5;
			return this.superDebtorDetailService.getSentMails(superDebtorIdentity.id, { ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorMailsSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorMailsFailure({error})))
			);
		})
	));

	loadComments = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorCommentsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectCommentsSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, comments, superDebtorIdentity]) => {
			const { filters, paging } = comments;
			return this.superDebtorDetailService.getComments(superDebtorIdentity.id, { ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorCommentsSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorCommentsFailure({error})))
			);
		})
	));

	showMoreComments = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ShowMoreSuperDebtorCommentRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectCommentsSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, comments, superDebtorIdentity]) => {
			const { filters, paging } = comments;
			paging.pageSize = comments.paging.pageSize + 5;
			return this.superDebtorDetailService.getComments(superDebtorIdentity.id, { ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorCommentsSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorCommentsFailure({error})))
			);
		})
	));

	addCommentEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddSuperDebtorCommentRequest),
		withLatestFrom(
			this.store.select(AppStoreSelectors.selectCurrentUser),
			this.sdPageStore.identityAsObservable()),
		switchMap(([action, user, superDebtorIdentity]) => {
			const comment: ICommentItem = {
				...action.comment,
				user: mapToUserAvatar(user.fullName),
				date: new Date(),
			};
			return this.superDebtorDetailService.sendComment(superDebtorIdentity.id, comment).pipe(
				switchMap(_ => [
					featureActions.AddSuperDebtorCommentSuccess({ comment }),
					featureActions.LoadSuperDebtorCommentsRequest(),
				]),
				catchError(error => of(featureActions.AddSuperDebtorCommentFailure({ error })))
			);
		})
	));

	updateCommentEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorCommentRequest),
		switchMap(action => this.superDebtorDetailService.updateComment(action.comment).pipe(
			switchMap(_ => [
				featureActions.UpdateSuperDebtorCommentSuccess({ comment: action.comment }),
				featureActions.LoadSuperDebtorCommentsRequest(),
			]),
		))
	));

	deleteCommentEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteSuperDebtorCommentRequest),
		switchMap(action => this.superDebtorDetailService.deleteComment(action.comment).pipe(
			switchMap(_ => [
				featureActions.DeleteSuperDebtorCommentSuccess({ comment: action.comment }),
				featureActions.LoadSuperDebtorCommentsRequest(),
			]),
		))
	));

	getEmailPreview = createEffect(() => this.actions$.pipe(
		ofType(featureActions.GetEmailPreview),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, superDebtorIdentity]) => {
			const toIds = action.mailData.toIds || []
			const toCcIds = action.mailData.toCcIds || []
			const filters = [this.contactsFiltersSpec.byId(...toIds, ...toCcIds)]
			// Fetch all contacts to produce an extended format
			// User Story 36905: Compléter les informations affichées pour les interlocuteurs
			return zip([
				this.superDebtorDetailService.getSuperDebtorContacts(superDebtorIdentity.id, { filters }),
				this.tpContactsService.getContacts({ filters }),
			]).pipe(
				map(contacts => contacts[0].concat(contacts[1].items)),
				switchMap(contacts => this.superDebtorDetailService.getMailPreview(superDebtorIdentity.id, action.mailData).pipe(
					map(entity => AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess({
						...entity,
						to: [ ...contacts.filter(c => toIds.includes(c.id)).map(c => mapToDisplayedContact(true, this.i18n, c, null, true).displayableName), entity.to].filter(Boolean).join(', '),
						toCc: [ ...contacts.filter(c => toCcIds.includes(c.id)).map(c => mapToDisplayedContact(true, this.i18n, c, null, true).displayableName), entity.toCc].filter(Boolean).join(', '),
					}, contacts))),
					catchError(error => of(AppStoreActions.Execute(() => this.mailWriter.onPreviewError(error)))
				))))
			}
		))
	);

	openSentMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentMailRequest),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, superDebtorIdentity]) => this.superDebtorDetailService.getSentMail(superDebtorIdentity.id, action.id).pipe(
			switchMap(entity => [
				featureActions.OpenSentMailSuccess({entity}),
				AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess(entity)),
			]),
			catchError(error => of(
				featureActions.SendSuperDebtorMailFailure({error}),
			))
		))
	));

	loadLetters = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorLettersRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectLettersSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, letters, superDebtorIdentity]) => {
			const { filters, paging } = letters;
			return this.superDebtorDetailService.getSentLetters(superDebtorIdentity.id,
				{ ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorLettersSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorLettersFailure({error})))
			);
		})
	));

	showMoreLetters = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ShowMoreSuperDebtorLettersRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectLettersSlice),
			this.sdPageStore.identityAsObservable()),
		switchMap(([, letters, superDebtorIdentity]) => {
			const { filters, paging } = letters;
			paging.pageSize = letters.paging.pageSize + 5;
			return this.superDebtorDetailService.getSentLetters(superDebtorIdentity.id,
				{ ...paging, filters }).pipe(
				map(list => featureActions.LoadSuperDebtorLettersSuccess({list})),
				catchError(error => of(featureActions.LoadSuperDebtorLettersFailure({error})))
			);
		})
	));

	openSentLetterRequest = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentLetterRequest),
		switchMap(action => this.documentFileService.getLocalFileURL({id: action.id, name: ''}).pipe(
			map(document => featureActions.OpenSentLetterSuccess({param: <string> document.url['changingThisBreaksApplicationSecurity']})),
			catchError(error => of(featureActions.OpenSentLetterFailure({error})))
		))
	));

	openSentLetterSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSentLetterSuccess),
		tap(action => {
			const modalRef = this.modalService.open(PdfViewerComponent, {size: 'lg', centered: true});
			const modalInstance: PdfViewerComponent = modalRef.componentInstance;
			modalInstance.title = 'Actions.PreviewPostalMail';
			modalInstance.url = action.param;
		})
	), {dispatch: false});
}
