/* eslint-disable @typescript-eslint/member-ordering */

import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DunningActionsFilterSpecService, DunningActionsService } from 'apps/middle/src/app/dunnings-module/services';
import { IStoreListStateSlice } from '@aston/foundation';
import { Injectable, inject } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { SuperDebtorDetailService } from 'apps/middle/src/app/debtors-module/services/super-debtor-detail.service';
import { switchMap, map, withLatestFrom, debounceTime } from 'rxjs/operators';
import { AccountingListSortProp } from 'apps/middle/src/app/accounting-module/enums';
import { IAccountingItemList, IAccountingListFilter } from 'apps/middle/src/app/accounting-module/models';

import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';
import { catchWithAppError as catchError } from '../../app-store/utils';
import * as AppStoreActions from '../../app-store/actions';
import { addAccountingSubTypeSorts } from '../../functions/helpers.function';
import { AccountingListFilterSpecService } from '../../../accounting-module/services/accounting-list-filter-spec.service';
import { AccountingDocumentsService } from '../../../accounting-module/services/accounting-documents.service';
import { DunningTimelineStore } from '../../../dunnings-module/stores/dunning-timeline.store';

@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreDunningEffects {

	private actions$ = inject(Actions);
	private store = inject(Store);
	private tlStore = inject(DunningTimelineStore);
	private superDebtorDetailService = inject(SuperDebtorDetailService);
	private accountingService = inject(AccountingDocumentsService);
	private accountingListFilterSpecService = inject(AccountingListFilterSpecService);
	private dunningActionsService = inject(DunningActionsService);
	private actionsFilterSpecService = inject(DunningActionsFilterSpecService);

	loadSuperDebtorDunningCaseRequestEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorDunningCasesRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([, superDebtorId]) =>
			this.superDebtorDetailService.getSuperDebtorDunningCases(superDebtorId).pipe(
				map(entity => featureActions.LoadSuperDebtorDunningCasesSuccess({ entity })),
				catchError(error => of(featureActions.LoadSuperDebtorDunningCasesFailure({error})))
			)
		)
	));

	loadDunningAccountingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDunningAccountingRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectDunningAccountingListSlice),
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectPayingCenterMode)),
		debounceTime(500),
		switchMap(([_, dunningAccountingDocuments, superDebtorId, payingCenterMode]) => {
			const { filters, paging } = this.getDunningAccountingDocumentsFilters(dunningAccountingDocuments);
			return zip(
				this.superDebtorDetailService.getSuperDebtorAccounting(superDebtorId, payingCenterMode, {	...paging, filters }),
				this.superDebtorDetailService.getSuperDebtorAccountingTotals(superDebtorId, filters)
			).pipe(
				map(([list, totals]) => featureActions.LoadDunningAccountingSuccess({list: {...list, meta: {totals}}})),
				catchError(error => of(featureActions.LoadDunningAccountingFailure({error})))
			);
		})
	));

	// Reload data when a UpdateSettingsAction is dispatched
	updateDunningAccountingSettingsAction$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateDunningAccountingSettings),
		map(_ => featureActions.LoadDunningAccountingRequest())
	));

	dunningAccountingSelectAllEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DunningAccountingDocumentsSelectionAllToggle),
		map(action => {
			return action.isSelected ?
				featureActions.LoadDunningAccountingDocumentsIdsListRequest() :
				featureActions.DunningAccountingDocumentsSelectionReset();
		})
	));

	loadDunningAccIdsListRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDunningAccountingDocumentsIdsListRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectDunningAccountingListSlice),
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectPayingCenterMode)),
		debounceTime(500),
		switchMap(([, dunningAccountingDocuments, superDebtorId, payingCenterMode]) => {
			const { filters, paging } = this.getDunningAccountingDocumentsFilters(dunningAccountingDocuments);
			return this.superDebtorDetailService.getSuperDebtorAccountingIds(superDebtorId, payingCenterMode, { ...paging, filters }).pipe(
				map(ids => featureActions.LoadDunningAccountingDocumentsIdsListSuccess({entity: ids})),
				catchError(error => of(featureActions.LoadDunningAccountingDocumentsIdsListFailure({error})))
			);
		})
	));

	stopDunningScenarioConfirm$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StopDunningScenarioConfirmAction),
		map(action => AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.StopDunningAction.`,
				questionParams: {data: {superDebtorName: action.superDebtorName}}
			}, featureActions.StopDunningScenarioRequest({param: action.dunningCaseId}))
		)));

	stopDunningScenarioRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StopDunningScenarioRequest),
		switchMap(action => this.dunningActionsService.stopDunningScenario(action.param).pipe(
			map(() => featureActions.StopDunningScenarioSuccess()),
			catchError(error => of(featureActions.StopDunningScenarioFailure({error})))
		))
	));

	startDunningScenarioConfirm$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartDunningScenarioConfirmAction),
		map(action => AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.StartDunningAction.`,
				questionParams: {data: {superDebtorName: action.superDebtorName}}
			},
			featureActions.StartDunningScenarioRequest({param: action.dunningCaseId}))
		)
	));

	startDunningScenarioRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartDunningScenarioRequest),
		switchMap(action => this.dunningActionsService.startDunningScenario(action.param).pipe(
			map(() => featureActions.StartDunningScenarioSuccess()),
			catchError(error => of(featureActions.StartDunningScenarioFailure({error})))
		))
	));

	startDunningScenarioSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartDunningScenarioSuccess, featureActions.StopDunningScenarioSuccess),
		withLatestFrom(this.store),
		switchMap(_ => [
			AppStoreActions.Execute(() => this.tlStore.loadTimeline()),
			featureActions.LoadSuperDebtorDunningCasesRequest(),
		])
	));

	onDunningDiagnosticFormRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DunningDiagnosticFormRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSuperDebtorId)),
		switchMap(([action, superDebtorId]) =>
			this.superDebtorDetailService.getSuperDebtorDunningDiagnostic(superDebtorId, action.date, action.dunningLevel).pipe(
				map(result => featureActions.DunningDiagnosticFormSuccess({param: result})),
				catchError(error => of(featureActions.DunningDiagnosticFormFailure({error})))
			)
		)
	));

	getDunningAccountingDocumentsFilters(dunningAccountingDocuments: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IAccountingListFilter>) {
		const {filters: otherFilters} = dunningAccountingDocuments;

		// hard filters
		// https://astonitf.visualstudio.com/LBP/_workitems/edit/10816
		const filters = [
			this.accountingListFilterSpecService.excludeCompletedDocuments,
			...otherFilters,
		];

		let paging = dunningAccountingDocuments.paging;

		// If sorting on type, add paymentType as second sort
		paging = {
			...paging,
			sorts: addAccountingSubTypeSorts(paging.sorts)
		};

		return { filters, paging };
	}

	loadDunningActionsCountNotCompletedHoldingAccountingDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDunningActionsCountNotCompletedHoldingSelectedAccountingDocumentsRequest),
		switchMap(action => {
			const listState = {filters: [
				this.actionsFilterSpecService.onlyNotYetRealized(),
			]};
			const source = action.withAllDocumentsOfDebtor ?
				{ superDebtorIds: [+action.withAllDocumentsOfDebtor] } :
				{ accountingDocumentIds: action.ids.map(Number) };
			return this.accountingService.getAccountingDocumentsDunningActionsCount(source, listState).pipe(
				map(count => featureActions.LoadDunningActionsCountNotCompletedHoldingSelectedAccountingDocumentsSuccess({entity: count})),
				catchError(error => of(featureActions.LoadDunningActionsCountNotCompletedHoldingSelectedAccountingDocumentsFailure({error})))
			);
		})
	));
}
