import { Action, createReducer, on } from '@ngrx/store';
import { reuseListSorts } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	on(featureActions.InitSuperDebtorPageRequest, (prev, action) => {
		const newSD = action.id !== prev.superDebtorId;
		const state = newSD ? initialState : prev;

		return {
			...state,
			isLoading: true,
			error: null,
			superDebtorId: action.id,
			payingCenterMode: action.payingCenterMode,
			// Reuse existing sorts
			// User Story 29225: [ACC][Client] Personnalisation des tris sur les listes au sein de la plateforme
			dunningAccountingDocuments: reuseListSorts(initialState.dunningAccountingDocuments, state.dunningAccountingDocuments),
			factoringEvents: reuseListSorts(initialState.factoringEvents, state.factoringEvents),
			attachedDebtors: reuseListSorts(initialState.attachedDebtors, state.attachedDebtors),
			debtors: reuseListSorts(initialState.debtors, state.debtors),
			// Init pageSize for list if showmore action change it
			comments: {
				...state.comments,
				paging : {
					...state.comments.paging,
					pageSize : 5
				}
			},
			mails: {
				...state.mails,
				paging : {
					...state.mails.paging,
					pageSize : 5
				}
			},
			letters: {
				...state.letters,
				paging : {
					...state.mails.paging,
					pageSize : 5
				}
			}
		}
	})
);
