import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { saveAs } from 'file-saver';
import { SuperDebtorDetailService } from 'apps/middle/src/app/debtors-module/services';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';

import { catchWithAppError as catchError, AppStoreActions } from '../../app-store';

import * as featureSelectors from './../selectors';
import * as featureActions from './../actions';


@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreFactoringEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private superDebtorDetailService: SuperDebtorDetailService) {
	}

	loadFactoringEventsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFactoringEventsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectFactoringEventsSlice)),
		switchMap(([, superDebtorId, factoringEvents]) => {
			const { filters, paging } = factoringEvents;
			return zip(
				this.superDebtorDetailService.getSuperDebtorFactoring(superDebtorId, { ...paging, filters }),
				this.superDebtorDetailService.getSuperDebtorFactoringTotals(superDebtorId, filters)
			).pipe(
				map(([list, totals]) => featureActions.LoadFactoringEventsSuccess({list: {...list, meta: {totals}}})),
				catchError(error => of(featureActions.LoadFactoringEventsFailure({error})))
			);
		})
	));

	exportFactoringEventsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportFactoringEventsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectFactoringEventsFilters)),
		switchMap(([action, superDebtorId, filters]) => {
			return this.superDebtorDetailService.exportSuperDebtorFactoring(superDebtorId, filters).pipe(
				map(response => {
					saveAs(response.blob, response.fileName);
					return featureActions.ExportFactoringEventsSuccess({correlationParams: action.correlationParams});
				}),
				catchError(error => of(
					featureActions.ExportFactoringEventsFailure({error}),
					AppStoreActions.ToastError('Errors.RetryableError')
				))
			);
		})
	));

	updateFactoringEventsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFactoringEventsSettings),
		map(_ => featureActions.LoadFactoringEventsRequest())
	));

	filterFactoringEventsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddFactoringEventsFilters, featureActions.RemoveFactoringEventsFilters),
		switchMap(_ => [
			featureActions.LoadFactoringEventsRequest(),
			featureActions.LoadFactoringKPIsRequest(),
		])
	));

	updateFactoringKPIsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFactoringKPIsRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectFactoringEventsFilters)),
		switchMap(([, superDebtorId, filters]) => {
			return this.superDebtorDetailService.getSuperDebtorFactoringKPI(superDebtorId, filters).pipe(
				map(entity => featureActions.LoadFactoringKPIsSuccess({entity})),
				catchError(error => of(featureActions.LoadFactoringKPIsFailure({error})))
			);
		})
	));
}
