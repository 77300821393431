import { Action, createReducer, on } from '@ngrx/store';
import { withEntityReducer, withListReducer } from '@aston/foundation';

import { initialState, ISuperDebtorPageState } from '../state';
import * as featureActions from '../actions';


export const reducer = createReducer<ISuperDebtorPageState, Action>(
	initialState,

	...withEntityReducer<ISuperDebtorPageState>('superDebtorDunningCase', featureActions.superDebtorDunningCasesActions),
	...withEntityReducer<ISuperDebtorPageState>('dunningActionsCountNotCompletedHoldingSelectedAccountingDocuments', featureActions.dunningActionsCountNotCompletedHoldingSelectedAccountingDocumentsActions),
	...withListReducer<ISuperDebtorPageState>('dunningAccountingDocuments', featureActions.dunningActionAccountingDocumentsActions),
	...withEntityReducer<ISuperDebtorPageState>('dunningAccountingDocumentsIdsList', featureActions.dunningAccountingDocumentsIdsActions),

	on(featureActions.DunningAccountingDocumentsSelectionAdd, (state, action) => {
		const list = { ...state.dunningAccountingDocuments };
		action.ids.forEach(id => list.list.items.find(item => item.accountingDocumentId === id).isSelected = true)
		const selectedIds = [...state.dunningAccountingDocumentsSelectedIds.filter(id => !action.ids.includes(id)), ...action.ids];
		const allSelected = selectedIds.length === list.list.paging.totalItemCount;

		return {
			...state,
			dunningAccountingDocuments: list,
			dunningAccountingDocumentsAllSelected: allSelected,
			dunningAccountingDocumentsSelectedIds: selectedIds
		};
	}),

	on(featureActions.DunningAccountingDocumentsSelectionRemove, (state, action) => {
		const list = { ...state.dunningAccountingDocuments };
		action.ids.forEach(id => list.list.items.find(item => item.accountingDocumentId === id).isSelected = false)
		const selectedIds = [...state.dunningAccountingDocumentsSelectedIds.filter(id => !action.ids.includes(id))];

		return {
			...state,
			dunningAccountingDocuments: list,
			dunningAccountingDocumentsAllSelected: false,
			dunningAccountingDocumentsSelectedIds: selectedIds
		};
	}),

	on(featureActions.DunningAccountingDocumentsSelectionAllToggle, (state, action) => {
		return {
			...state,
			dunningAccountingDocumentsAllSelected: action.isSelected
		};
	}),

	on(featureActions.DunningAccountingDocumentsSelectionReset, state => {
		const list = { ...state.dunningAccountingDocuments };
		const items = list.list.items.map(item => ({ ...item, isSelected: false }));
		list.list.items = items;

		return {
			...state,
			dunningAccountingDocuments: list,
			dunningAccountingDocumentsAllSelected: false,
			dunningAccountingDocumentsSelectedIds: []
		};
	}),

	on(featureActions.LoadDunningAccountingDocumentsIdsListSuccess, (state, action) => {
		const list = { ...state.dunningAccountingDocuments };
		const items = list.list.items.map(item => ({ ...item, isSelected: true }));
		list.list.items = items;

		return {
			...state,
			dunningAccountingDocuments: list,
			dunningAccountingDocumentsIdsList: { ...state.dunningAccountingDocumentsIdsList, entity: action.entity },
			dunningAccountingDocumentsSelectedIds: [...action.entity]
		};
	}),

	on(featureActions.LoadDunningAccountingSuccess, state => {
		const selectedIds = state.dunningAccountingDocumentsSelectedIds;
		const list = { ...state.dunningAccountingDocuments };
		const items = list.list.items.map(item => ({ ...item, isSelected: selectedIds.includes(item.accountingDocumentId) }));
		list.list.items = items;

		return {
			...state,
			dunningAccountingDocuments: list
		};
	}),

	on(featureActions.SetSuperDebtorDunningCaseSTDId, (state, action) => ({
		...state,
		superDebtorDunningCaseSTDId: action.param,
	})),

	on(featureActions.DunningDiagnosticFormOpenAction, state => ({
		...state,
		superDebtorDunningDiagnosticFormIsOpened: true,
	})),

	on(featureActions.DunningDiagnosticFormCloseAction, state => ({
		...state,
		superDebtorDunningDiagnosticFormIsOpened: false,
	})),


	on(featureActions.StopDunningScenarioRequest, featureActions.StartDunningScenarioRequest, state => ({
		...state,
		superDebtorDunningCase: {
			...state.superDebtorDunningCase,
			isLoading: true,
			error: null
		}
	})),

	on(featureActions.StartDunningScenarioFailure, featureActions.StopDunningScenarioFailure, (state, action) => ({
		...state,
		superDebtorDunningCase: {
			...state.superDebtorDunningCase,
			isLoading: false,
			error: action.error
		}
	})),

	on(featureActions.StartDunningScenarioSuccess, featureActions.StopDunningScenarioSuccess, state => ({
		...state,
		superDebtorDunningCase: {
			...state.superDebtorDunningCase,
			isLoading: false,
			error: null
		}
	})),

	on(featureActions.DunningDiagnosticFormRequest, state => ({
		...state,
		superDebtorDunningDiagnosticForm: {
			...state.superDebtorDunningDiagnosticForm,
			isLoading: true,
			error: null
		}
	})),

	on(featureActions.DunningDiagnosticFormSuccess, (state, action) => ({
		...state,
		superDebtorDunningDiagnosticForm: {
			...state.superDebtorDunningDiagnosticForm,
			entity: action.param,
			isLoading: false,
			error: null
		}
	})),

	on(featureActions.DunningDiagnosticFormFailure, (state, action) => ({
		...state,
		superDebtorDunningDiagnosticForm: {
			...state.superDebtorDunningDiagnosticForm,
			isLoading: false,
			error: action.error
		}
	})),
);
